.text-area {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid transparent;
    outline: none;
    border-radius: 10px;
    background-color: var(--background-color);
    color: var(--primary-color);
    box-sizing: border-box;
    resize: vertical; /* Allow vertical resizing */
    overflow-y: scroll;
  }

.text-area:hover {
    border: 1px solid var(--button-color-opacity); /* Border when focused */
}

.text-area:focus {
    border: 1px solid var(--button-color); /* Border when focused and active */
  }

.title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
 }

.button-container2 {
    display: flex;
    align-items: center;
}

.copy-button {
    background-color: var(--button-color);
    border: none;
    font-size: 1.1rem;
    padding-block: 0.5rem;
    border-radius: 10px;
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: "CustomFont3";
 }
.copy-button:hover {
    background-color: var(--button-color-opacity);
    color: rgb(179, 179, 179);
}