.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grid {
  margin-inline: 5vw;
  max-width: 100%;
  height: 100%;
  display: grid;
  gap: 1.5vw;
  padding: 1vw;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}
.item {
  text-align: left;
  padding: 1rem;
  color: var(--primary-text-color);
  border-radius: 10px;
  background-color: var(--foreground-color);
}
.item2 {
  text-align: left;
  grid-column: span 2;
  padding: 1rem;
  color: var(--primary-text-color);
  border-radius: 10px;
  background-color: var(--foreground-color);
}
.heading {
  padding-inline: 1rem;
  text-align: left;
  color: var(--primary-text-color);
  font-size: 2rem;
  margin-block: 1.5rem;
}

.line-list {
  height: 20rem;
  overflow-y: scroll;
  margin-top: 1rem;
  border-radius: 10px;
}

.line-item {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 0.5rem 0;
  padding-inline: 0.5rem;
  font-family: "CustomFont2";
}

.line-item span {
  padding: 5px;
}

.line-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.line-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: none; /* Remove bottom border for a smoother look */
}

.line-item:nth-child(even) {
  background-color: rgb(26, 26, 26); /* Different background for even lines */
}

.line-input {
  color: var(--primary-text-color);
  margin-left: 1rem;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid transparent;
  font-family: "CustomFont2";
  text-align: right;
}


/* Targeting inputs inside odd .line-item elements */
.line-item:nth-child(odd) .line-input {
  background-color: var(--background-color);
  color: var(--primary-text-color);
}

/* Targeting inputs inside even .line-item elements */
.line-item:nth-child(even) .line-input {
  background-color: rgb(26, 26, 26);
  color: var(--primary-text-color);
}

.line-input:focus {
  outline: none;
  border-color: var(--button-color);
}

/* Hide the default number input spinner */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Hide for Firefox */
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Stretch button container to full height */
  margin-top: 1rem;
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 1rem;
  gap: 1rem;
  width: 100%; /* Full width of the parent container */
  box-sizing: border-box; /* Include padding in width calculation */
}
.apply-all-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.apply-all-checkbox {
  margin-right: 1rem;
  cursor: pointer;
}

.apply-all-input {
  margin-left: 1rem;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  text-align: right;
  width: 6rem;
  transition: opacity 0.2s ease;
}

.apply-all-input:hover {
  outline: none;
  border: 1px solid var(--button-color-opacity);
}

.apply-all-input:focus {
  outline: none;
  border: 1px solid var(--button-color);
}

.apply-all-input.disabled {
  opacity: 0.5; /* Lower opacity when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
}

.apply-button {
  margin-left: 1rem;
  padding: 6px 12px;
  background-color: var(--button-color);
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.apply-button:disabled {
  background-color: #c1c1c1;
  cursor: not-allowed;
}

.apply-button:hover:not(:disabled) {
  background-color: var(--button-color-opacity);
  color: rgb(179, 179, 179);
}


.button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 0.5rem;
  width: 400px; /* Fixed width for button container */
}
.right-side {
  display: flex;
  justify-content: flex-end; /* Push button container to the right */
  margin-top: 1rem;
  width: 100%; /* Full width of the parent container */
  box-sizing: border-box; /* Include padding in width calculation */
}

.summary-button {
  padding: 0.5rem;
  background-color: var(--button-color);
  color: var(--background-color);
  font-size: 1rem;
  font-family: "CustomFont3";
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  width: 100%; /* Ensure buttons take full width */
}


.summary-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .button-container {
    grid-template-columns: 1fr; /* Switch to 1 column on smaller screens */
    grid-template-rows: repeat(2, 1fr); /* Ensure buttons span evenly */
    width: 100%; /* Make sure the button container spans the full width */
  }
  .summary-button {
    padding: 0.5rem;
  }
  .summary {
    flex-direction: column;
  }

  .grid {
    margin-inline: 5vw;
    max-width: 100%;
    height: 100%;
    display: grid;
    gap: 1.5vw;
    padding: 1vw;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }

  .item2 {
    text-align: left;
    grid-column: span 1;
    padding: 1rem;
    color: var(--primary-text-color);
    border-radius: 10px;
    background-color: var(--foreground-color);
  }
}