.grid {
    max-width: 100%;
    height: 100%;
    display: grid;
    gap: 1.5vw;
    padding: 1vw;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  .item {
    text-align: left;
    padding: 1rem;
    color: var(--primary-text-color);
    border-radius: 10px;
    background-color: var(--foreground-color);
  }
  
  .heading {
    padding-inline: 1rem;
    text-align: left;
    color: var(--primary-text-color);
    font-size: 2rem;
    margin-block: 1.5rem;
  }


/* Styles for custom dropdown */
.dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;

  }
  
  .dropdown-header {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-color 0.3s ease;
  }
  .dropdown-header-color {
    width: 12px; /* Size of the colored circle */
    height: 12px;
    border-radius: 50%;
    margin-right: 10px; /* Space between the circle and text */
    display: inline-block; /* Ensure the circle stays in line with the text */
  }
  .dropdown-header:hover { 
    border: 1px solid var(--button-color-opacity); /* Border when focused */
  }

  .dropdown-header.focused {
    border: 1px solid transparent; /* Border when focused */
  }
  
  .dropdown-header.focused:hover { 
    border: 1px solid var(--button-color-opacity); /* Border when focused */
  }

  .dropdown-header.active.focused {
    border: 1px solid var(--button-color); /* Border when focused and active */
  }

  .dropdown-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }

  .dropdown-list {
    border-radius: 10px;
    position: absolute;
    width: 100%;
    border: none;
    background-color: var(--background-color);
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-item {
    padding: 10px;
    margin: 0;
    background-color: var(--background-color);
  }
  
  .dropdown-item-color {
    width: 12px; /* Size of the colored circle */
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block; /* Ensure the circle stays in line with the text */  }

  .dropdown-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .dropdown-item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none; /* Remove bottom border for a smoother look */
  }

  .dropdown-item:hover {
    background-color: var(--foreground-color);
  }
  
  
.dropdown-input-container {
    margin-top: 0.5rem;
    border-radius: 10px;
  }
  
  .dropdown-input {
    color: var(--primary-text-color);
    background-color: var(--background-color);
    width: 100%;
    margin-top: 0.5rem;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding in width */
  }

  .dropdown-input:hover { 
    border: 1px solid var(--button-color-opacity); /* Border when focused */
  }
  .dropdown-input:focus { 
    outline: none;
    border: 1px solid var(--button-color); /* Border when focused */
  }
  .balance-label {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  .balance-value {
    margin-top: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
  }