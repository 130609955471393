body {
  margin: 0;
  font-family: 'CustomFont2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'CustomFont';
  src: url('../Fonts/CreatoDisplay-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'CustomFont2';
  src: url('../Fonts/CreatoDisplay-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'CustomFont3';
  src: url('../Fonts/CreatoDisplay-Bold.otf') format('opentype');
}

:root {
  --sale-text: rgb(14, 14, 14);
  --sale-color: linear-gradient(to bottom right,rgb(21, 255, 126) , rgb(0, 148, 168));
  --background-color: rgb(13,14,13);
  --foreground-color:  rgb(18,19,18);
  --switch-icon:  rgb(226, 226, 226);
  --primary-text-color: rgb(255, 255, 255);
  --secondary-text-color: rgb(128, 128, 128);
  --button-color: rgb(255, 232, 21);
  --button-color-opacity: rgba(225, 205, 21, 0.5);
  --button-color-staking: rgb(21, 255, 138);
  --button-color-off: rgba(21, 255, 138, 0.5);
  --button-hover-color: rgb(0, 170, 85);
  --button-text: rgb(255,255,255);
  --connect-accent: rgb(128, 128, 128);
  --button-color-stake: rgb(112, 61, 253);
}
