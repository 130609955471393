/* General reset for margin and padding within the nav */
  header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
  }

  .navbar {
    background-color: var(--foreground-color);
    padding-left: 5rem;
    padding-right: 4rem;
    width: 100%;
    font-size: 1.4rem;
    z-index: 1000;
    position: sticky;
  }
  @media only screen and (max-width: 1320px) {
    .navbar {
      padding-inline: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 1.4rem;
    };
}

nav a{
  height: 100%;
  padding: 0 1.6rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
};


  header .navbar.sticky {
    color: white; /* Adjust text color if needed */
    z-index: 1000;
    position: sticky;
  }

  nav ul{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    padding-left: 0;
    margin: 0;
  }
  nav li{
    height: 70px;
  }

  nav a:hover{
    color: var(--secondary-text-color);
  }

  .navbar .Connect:hover {
    background-color: transparent;
  }

  nav li .menu-button:hover {
    border-radius: 0;
    background-color: transparent;
  }

  nav li:first-child{
    margin-right: auto;
  }
  .sidebar{
    position: fixed;
    top: 0; 
    right: 0;
    height: 100vh;
    width: 350px;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    list-style: none;
    display: none;
    flex-direction: column;
    text-align: end;

    align-items: flex-start;
    justify-content: flex-start;
  }

  .sidebar.visible {
    display: block;
    background: var(--background-color);
  }

  .sidebar li{
    width: 100%;
    text-align: left;
  }
  .sidebar a{
    width: 100%;
    text-align: left;
  }

  .sidebar a:hover{
    background-color: var(--button-color);
    color: var(--primary-text-color);
    border-radius: 0;
  }

  .menu-button{
    color: var(--primary-text-color);
    display: none;

  }

  .menu-button:hover{
    background-color: transparent;
    color: var(--primary-text-color);
    border-radius: 0;
  }

  /* Hamburger icon styling */
  nav .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
  }
  
  
  nav .hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background: var(--primary-text-color);
    border-radius: 2px;
  }

 /* Hamburger icon styling */
   nav .x {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 15px;
    text-align: right;
  }
  
  nav .x span {
    display: block;
    height: 3px;
    width: 100%;
    background: var(--primary-text-color);
    border-radius: 2px;
    text-align: right;
  }

  nav .x.open span:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 5px
  }
  
  nav .x.open span:nth-child(2) {
    opacity: 0;
  }
  
  nav .x.open span:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -7px
  }
  
  .logo {
    padding: 0 1.6rem;   
  }


  @media screen and (min-width: 896px){
    .sidebar{
      width: 0;
    }
  }

  @media screen and (max-width: 895px){
    .hideOnMobile{
      display: none;
    }
    .menu-button{
      display: block;
    }
    .sidebar{
      display: none;
    }

  }
  @media(max-width: 400px){
    .sidebar{
      width: 100%;
    };
    .sidebar a:hover{
      background-color: transparent;
      color: var(--primary-text-color);
      border-radius: 0;
    };
    nav a{
      height: 100%;
      padding: 0 1rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--primary-text-color);
    };
  }